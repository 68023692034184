import React from "react";

class Player extends React.Component {
  constructor(props) {
    super(props);
    this.oldLegacy = this.props.legacy;
    this.state = {
      player: null,
    };
    this.legacyUrl = this.props.url.split('wss://').join('https://') + '/playlist.m3u8';
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate() {
    if (this.props.legacy != this.oldLegacy) {
      this.oldLegacy = this.props.legacy;
      if (!this.props.legacy) this.playerLegacy.dispose();
      if (this.props.legacy)
        this.state.player.destroy((e) => {
          console.log("switched to legacy");
        });
      this.init();
    }
  }

  init() {
    if (this.props.legacy) {
      this.playerLegacy = window.videojs(
        "vidjs_player_wrapper" + this.props.index
      );
      this.playerLegacy.src([
        {
          type: "application/x-mpegURL",
          src: this.legacyUrl,
        },
      ]);
      this.playerLegacy.play();

      return;
    }

    if (this.props.sync)
      return this.setState(
        Object.assign({}, this.state, {
          player: window.SLDP.init({
            container: "sldp_player_wrapper" + this.props.index,
            stream_url: this.props.url,
            sync_buffer: this.props.syncBuffer,
            buffer: this.props.buffer,
            width: "parent",
            autoplay: true,
            muted: true,
          }),
        })
      );

    this.setState(
      Object.assign({}, this.state, {
        player: window.SLDP.init({
          container: "sldp_player_wrapper" + this.props.index,
          stream_url: this.props.url,
          buffer: this.props.buffer,
          width: "parent",
          autoplay: true,
          muted: true,
        }),
      })
    );
  }

  render() {
    if (this.props.legacy)
      return (
        <React.Fragment>
          <h5 className="titleVideo">{this.props.name.toUpperCase()}</h5>
          <video-js
            id={"vidjs_player_wrapper" + this.props.index}
            class="vjs-default-skin vjs-16-9"
            controls
          >
            <source
              id="souceEl"
              src={this.legacyUrl}
              type="application/x-mpegURL"
            />
          </video-js>
        </React.Fragment>
      );

    return (
      <React.Fragment>
        <h5 className="titleVideo">{this.props.name.toUpperCase()}</h5>
        <div
          id={"sldp_player_wrapper" + this.props.index}
          style={{ width: "100%", height: "100%" }}
        ></div>
      </React.Fragment>
    );
  }
}

export default Player;