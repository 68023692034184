import React from "react";
import Chat from "./Chat.jsx";
import Player from "./Player.jsx";

function render(parent) {
  if (
    !parent.state.players ||
    !parent.state.playerSettings ||
    !parent.state.players[0]
  )
    return null;
  return (
    <React.Fragment>
      <div
        className={
          parent.state.players && parent.state.players.length === 2
            ? "container double-player"
            : "container"
        }
      >
        <div className="d-flex flex-column flex-md-row align-items-center px-3 pb-0 px-md-4">
          <div className="col-auto">
            <img
              src={parent.state.logo}
              alt="virtualset logo"
              className="logo"
            />
          </div>
          <div className="col"></div>
          <div className="col-auto">
            <div className="logout" id="logout">
              <i className="fas fa-user"></i> {parent.state.userName}{" "}
              <i
                className="fas fa-sign-out-alt ml-4 pointer"
                onClick={() => {
                  parent.logout();
                }}
              ></i>
            </div>
          </div>
        </div>
        <div className="row mb-3 mt-3">
          <div className="col text-center">
            <p>
              For a better experience, we highly recommend connecting via
              laptops or personal computers. However, it’s possible to access
              the platform from mobile devices and watch one video stream at a
              time. <div className="mb-2" />
              Click below and download SLDP app.
            </p>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-auto">
            <a
              href="itms-apps://itunes.apple.com/app/apple-store/id1238237026?mt=8"
              target="blank"
              className="mobile-btn mw-90"
            >
              <i className="fas fa-download"></i> Download
            </a>
          </div>
        </div>
        <div className="row mt-4 mb-3">
          <div className="col text-center">
            <p>
              Use the below buttons to launch the stream and to switch from one
              channel to the other.
            </p>
          </div>
        </div>
        <div className="row justify-content-center">
          {parent.state.players.map((player, key) => {
            const completeUrl = `${parent.state.playerSettings.baseUrl.replace(
              "wss://",
              "sldps://"
            )}${parent.state.liveId}-${player.id}?steady=true&buffering=${
              parent.state.playerSettings.buffer || 2000
            }`;

            return (
              <div className="col-auto" key={key}>
                <a
                  href={completeUrl}
                  target="blank"
                  className="mobile-btn"
                >
                  {player.name}
                </a>
              </div>
            );
          })}
        </div>

        <div className="row justify-content-center">
          <div className="col-auto">
              <p className="mb-3  mt-3">
                If you don't care about latency:{" "}
                <strong
                  className="pointer"
                  onClick={(e) => {
                    parent.setState({
                      legacyMode: !parent.state.legacyMode,
                    });
                  }}
                >
                  USE BROWSER
                </strong>
              </p>
          </div>
        </div>

        {parent.state.hasChat ? (
          <Chat
            channel={parent.state.channel}
            chatExpire={parent.state.chatExpire}
            hasAudio={parent.state.hasAudio}
            hasChat={parent.state.hasChat}
            userId={parent.state.userId}
            userName={parent.state.userName}
            muted={parent.state.mode === "muted"}
            messages={parent.state.messages}
            sendMessage={(m) => {
              parent.sendMessage(m);
            }}
          />
        ) : null}
      </div>
    </React.Fragment>
  );
}
export default render;
