import React from 'react';
// import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import './App.css';
import './Player.jsx';
import Login from './Login.jsx';
import Main from './Main.jsx';
import Mobile from './Mobile.jsx';
import {
  isMobileOnly,
  // isAndroid,
  isIOS,
  // isMobile,
  // isTablet,
  // MobileOnlyView
} from "react-device-detect";

const baseUrl = 'https://api.virtualset.it/v2';
class App extends React.Component {

  ws= null;

  prevLogin="new";

  constructor (props) {
    super(props);
    this.state = {
      loggedIn: false,
      loading: false,
      error: false,
      name: "guest " + genSession(5),
      password: "demo2021",
      channel: "",
      chatExpire: 0,
      hasAudio: false,
      hasChat: true,
      iat: 0,
      liveName: "",
      logo: "",
      players: [],
      playerSettings: {},
      userId: "",
      userName: "",
      liveId: "",
      mode: "",
      messages: [],
      legacyMode: false,
      isMobile: false
    };
    let token = localStorage.getItem('live-virtualset-demo-token');
    if (token) {
      this.state.loggedIn= true;
      const decoded = jwt_decode(token);
      console.log();
      if(!decoded || !decoded.userName || !decoded.liveId) return;
      this.state.name = decoded.userName;
      this.state.password = decoded.liveId;
      this.state.loading = true;
    }

  }

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  }

  sendMessage (message) {
    let msg = {
      type: "chatMessage",
      message: message
    }
    try {
      if (this.ws) return this.ws.send(JSON.stringify(msg));
    this.startWs();
    setTimeout(()=>{try {this.ws.send(JSON.stringify(msg));} catch {}},2000)
    } catch {}
  }

  componentDidMount() {
    if (isIOS && isMobileOnly) this.setState({isMobile: true});
    // if (isIOS && isMobileOnly) this.setState({legacyMode: true});

    if (localStorage.getItem('live-virtualset-demo-token'))
    this.login();

    setInterval(() => {
      let msg = {
        type: "authUpdate"
      }
      try {
        if (this.ws) return this.ws.send(JSON.stringify(msg));
      this.startWs();
      setTimeout(()=>{
        try {this.ws.send(JSON.stringify(msg));} catch {}
      },2000)
      } catch {}
    }, 20000);

    //document.body.style.backgroundColor = "red"
    document.body.style.backgroundImage = this.state.loggedIn? null: "url(/cool-background9.jpg)";
  }


componentWillUnmount () {
    document.body.style.backgroundColor = null;
}

componentDidUpdate () {
  
  if (this.state.loggedIn != this.prevLogin) {
    this.prevLogin = this.state.loggedIn;
    document.body.style.backgroundImage = this.state.loggedIn? null: "url(/cool-background9.jpg)";
  }

  
}
  
  keyDown (event){
    if(event.keyCode =='13'){
      this.login();
    }
  }

  render() {
    // if (!this.state.loggedIn) return Login(this);
    if (!this.state.loggedIn) {
      if (!this.state.loading) this.login();
      return (
        <div>Attendi...</div>
      );
    }
    if (this.state.isMobile && !this.state.legacyMode) return Mobile(this);
    return Main(this);
  }

  vote () {
    
  }

  async login () {
    this.state.loading = true;
    let browserId = localStorage.getItem('browserId'); 
    if (!browserId) {
      browserId = genSession();
      localStorage.setItem('browserId', browserId);
    }

    let response = await fetch(`${baseUrl}/auth`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
          id: this.state.password.trim(),
          nome: this.state.name.trim(),
          sessione: browserId,
          browser: {
            codeName:navigator.appCodeName,
            name: navigator.vendor,
            version: navigator.appVersion,
            cookies: navigator.cookieEnabled,
            platform: navigator.platform,
            userAgent: navigator.userAgent
          }
      })
    });
    
    if (!response.ok) {
      response.text().then(res => console.log(res));
      this.setState({
        error: true,
        loading: false
      });
      return;
    }
    let data = {};
    try {data = await response.json()}
    catch (e) {console.log(e.message)}
    if (data && data.token)
    localStorage.setItem('live-virtualset-demo-token', data.token);
    const decoded = jwt_decode(data.token);
    this.setState( Object.assign({}, this.state, decoded));
    this.setState({ loggedIn: true, loading: false });

    this.startWs();
  }

  logout() {
    localStorage.removeItem('live-virtualset-demo-token');
    this.setState( Object.assign({}, this.state, {
      loggedIn: false,
      error: false,
      name: "",
      password: "",
      loading: false
    }));
  }

  startWs() {
    var self = this;
    self.ws = new WebSocket(
      'wss://api.virtualset.it/v2/wss', 
      localStorage.getItem('live-virtualset-demo-token')
    );
    
    self.ws.onmessage = function(event) {
      let obj = {};
        try {
            obj = JSON.parse(event.data);
        } catch {}
        if (!obj || !obj.type) return;
        self.parseWs(obj);
    };
    
    self.ws.onclose = function(event) {
      if (event.wasClean) {
        console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
      } else {
        console.log('[close] Connection died');
        setTimeout(()=>{self.startWs();},4000);
      }
    };
    
    self.ws.onerror = function(error) {
      console.log(`[error] ${error.message}`);
      setTimeout(()=>{self.startWs();},4000);
    };
  }

  parseWs(obj) {
    if (obj.type === "chatUpdate") {
      for (let message of obj.messages) {
        if (message.userId ==  this.state.userId) message.isMe = true;
        message.time = new Date(message.time);
        message.time = `${calculateDayString(message.time)} ${calcHour(message.time)}`;
      }
      this.setState(Object.assign({}, this.state, { messages: obj.messages }));
      return;
    }
    if (obj.type === "reloadPage") {
      if (obj.forced) window.location.reload();
      return;
    }

    if (obj.type === "logout") {
      if (obj.forced) this.logout();
      return;
    }

  }

}

function pad(s) { return (s < 10) ? '0' + s : s; }

function calcHour (time) {
  return `${pad(time.getHours())}:${pad(time.getMinutes())}`;
}

function calculateDayString (time) {
  const today = new Date().getDate()
  if (today - time.getDate() == 0) return 'Today';
  if (today - time.getDate() == 1) return 'Yesterday';
  return [pad(time.getDate()), pad(time.getMonth()+1), time.getFullYear()].join('/')

}

function genSession(len=24) {
  let result = "";
  const asset = "qwertyuioplkjhgfdsazxcvbnmMNBVCXZASDFGHJKLPOIUYTREWQ1234567890";
  for (let i = 0; i<len; i++) result+= asset[Math.round(Math.random()*(asset.length-1))];
  return result;
}



export default App;