import React from "react";
import loading from "./loading.svg";
import logo from "./img/streamset-bianco.svg";

function render(parent) {
  return (
    <React.Fragment>
      <div
        className="container"
        onKeyDown={(e) => {
          parent.keyDown(e);
        }}
      >
        <div className="row justify-content-md-center">
          <div className="col-12 col-md-9 col-lg-8">
            <div className="card login-card">
              <div className="row justify-content-center">
                <div className="col-12">
                <img
              src={logo}
              alt="virtualset logo"
              className="login-logo"
            />
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-12 p-2">
                  <div className="wrap-input100 validate-input">
                    <input
                      className="input100"
                      type="text"
                      id="nome"
                      name="nome"
                      placeholder="Nome..."
                      onKeyDown={(e) => {
                        parent.keyDown(e);
                      }}
                      value={parent.state.name}
                      onChange={(event) => {
                        parent.setState(
                          Object.assign({}, parent.state, {
                            name: event.target.value,
                          })
                        );
                      }}
                    />
                    <span className="focus-input100"></span>
                    <span className="symbol-input100">
                      <i className="far fa-user" aria-hidden="true"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-12 p-2">
                  <div className="wrap-input100 validate-input">
                    <input
                      className="input100"
                      type="password"
                      name="id"
                      id="id"
                      placeholder="Password..."
                      onKeyDown={(e) => {
                        parent.keyDown(e);
                      }}
                      value={parent.state.password}
                      onChange={(event) => {
                        parent.setState(
                          Object.assign({}, parent.state, {
                            password: event.target.value,
                          })
                        );
                      }}
                    />
                    <span className="focus-input100"></span>
                    <span className="symbol-input100">
                      <i className="fa fa-lock" aria-hidden="true"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-12 p-2">
                  <div className="container-login100-form-btn">
                    <button
                      className="login100-form-btn submit2"
                      onClick={() => {
                        parent.login();
                      }}
                      onKeyDown={(e) => {
                        parent.keyDown(e);
                      }}
                      type="submit"
                    >
                      {parent.state.loading ? (
                        <img src={loading} alt="loading..." />
                      ) : (
                        "Entra"
                      )}
                    </button>
                  </div>
                </div>
              </div>
              {parent.state.error ? (
                <div className="row justify-content-center">
                  <div className="col-12 p-2">
                    <div className="alert alert-danger w3-animate-fading">
                      <div>Code invalid or expired. Retry!</div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {/* <div id="footer">
            <p className="love">Made with <i className="icon ion-heart"></i> by <a href="https://www.tokiostudio.it/">tokiostudio.it</a></p>
          </div> */}
    </React.Fragment>
  );
}
export default render;
