import React from "react";
import "./Chat.css";

class Chat extends React.Component {
  messagesEnd = null;
  messagesFrame = null;

  constructor(props) {
    super(props);
    this.state = {
      pendingMessage: "",
    };
  }

  scrollToBottom = () => {
    //this.messagesEnd.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    var scrollOptions = {
      left: 0,
      top: 1000000000,
      behavior: "smooth",
    };

    if (this.messagesFrame) this.messagesFrame.scrollTo(scrollOptions);
  };

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  keyDown(event) {
    if (event.keyCode === 13) {
      this.sendMessage();
    }
  }

  sendMessage() {
    const msg = this.state.pendingMessage;
    this.setState(Object.assign({}, this.state, { pendingMessage: "" }));

    this.props.sendMessage(msg);
  }

  render() {
    if (!this.props.hasAudio)
      return (
        <React.Fragment>
          <div
            className="row  justify-content-center mb-0 mb-sm-3 mt-0 mt-sm-4"
            id="chat"
          >
            <div className="col-12 col-lg-10 chat-container">
              <div className="row p-3" id="chatbox">
                <div className="card col-12 p-0">
                  <div
                    id="messages"
                    className="card-body p-1"
                    ref={(el) => {
                      this.messagesFrame = el;
                    }}
                  >
                    {this.props.messages.map((message, key) => {
                      return (
                        <div
                          className={
                            message.isMe
                              ? "card msgBoxMyself"
                              : "card msgBoxOthers"
                          }
                          key={key}
                        >
                          <div className="msgBoxUsername">
                            {message.userName}
                          </div>
                          <div className="msgBoxBody">
                            {message.isFile ? (
                              <a href={message.messaggio} target="null">
                                <i
                                  className="fas fa-download"
                                  style={{ marginRight: "14px" }}
                                ></i>
                                {message.title}
                              </a>
                            ) : (
                              message.messaggio
                            )}
                          </div>
                          <div className="msgBoxDate">{message.time}</div>
                        </div>
                      );
                    })}

                    <div
                      style={{ float: "left", clear: "both" }}
                      ref={(el) => {
                        this.messagesEnd = el;
                      }}
                    ></div>
                  </div>
                </div>
              </div>

              <div
                className="row px-3 pt-sm-3 pb-sm-4"
                id="messageBox"
                onKeyDown={(e) => {
                  this.keyDown(e);
                }}
              >
                <div className="col-12 input-group p-0">
                  <input
                    type="text"
                    className="form-control chat"
                    disabled={this.props.muted}
                    id="sendMessage"
                    placeholder="Write your message here..."
                    value={this.state.pendingMessage}
                    onChange={(event) => {
                      this.setState(
                        Object.assign({}, this.state, {
                          pendingMessage: event.target.value,
                        })
                      );
                    }}
                  />
                  <div className="input-group-append">
                    <button
                      id="replyBtn"
                      className="btn btn-danger"
                      disabled={this.props.muted}
                      type="submit"
                      onClick={(e) => {
                        this.sendMessage();
                      }}
                    >
                      <i className="fas fa-paper-plane"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
  }
}

//   channel={this.props.channel}
//   chatExpire={this.props.chatExpire}
//   hasAudio={this.props.hasAudio}
//   hasChat={this.props.hasChat}
//   userId={this.props.userId}
//   userName={this.props.userName}

export default Chat;
